var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"card",on:{"click":function($event){return _vm.register('Jan')}}},[_vm._m(0)])]),_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"card",on:{"click":function($event){return _vm.register('Marjan')}}},[_vm._m(1)])]),_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"card",on:{"click":function($event){return _vm.register('Alida')}}},[_vm._m(2)])]),_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"card",on:{"click":function($event){return _vm.register('Rogier')}}},[_vm._m(3)])]),_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"card",on:{"click":function($event){return _vm.register('Lisanne')}}},[_vm._m(4)])]),_c('div',{staticClass:"column is-full"},[_c('div',{staticClass:"card",on:{"click":function($event){return _vm.register('Christian')}}},[_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('h1',{staticClass:"title is-1"},[_vm._v("Jan")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('h1',{staticClass:"title is-1"},[_vm._v("Marjan")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('h1',{staticClass:"title is-1"},[_vm._v("Alida")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('h1',{staticClass:"title is-1"},[_vm._v("Rogier")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('h1',{staticClass:"title is-1"},[_vm._v("Lisanne")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('h1',{staticClass:"title is-1"},[_vm._v("Christian")])])])
}]

export { render, staticRenderFns }