<template>
  <!-- Jan, Marjan, Alida, Rogier, Lisanne, Christian -->
  <div>
    <div class="column is-full">
      <div class="card" @click="register('Jan')">
        <div class="card-content">
          <div class="content">
            <h1 class="title is-1">Jan</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="column is-full">
      <div class="card" @click="register('Marjan')">
        <div class="card-content">
          <div class="content">
            <h1 class="title is-1">Marjan</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="column is-full">
      <div class="card" @click="register('Alida')">
        <div class="card-content">
          <div class="content">
            <h1 class="title is-1">Alida</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="column is-full">
      <div class="card" @click="register('Rogier')">
        <div class="card-content">
          <div class="content">
            <h1 class="title is-1">Rogier</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="column is-full">
      <div class="card" @click="register('Lisanne')">
        <div class="card-content">
          <div class="content">
            <h1 class="title is-1">Lisanne</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="column is-full">
      <div class="card" @click="register('Christian')">
        <div class="card-content">
          <div class="content">
            <h1 class="title is-1">Christian</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {};
  },
  methods: {
    register(name) {
      this.$socket.emit("register", name);
      this.$router.push("/waiting");
    },
  },
};
</script>

<style>
/* Centered 4 rows, 1 column with Bulma CSS */
.columns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10%;
}
</style>
