import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/StartView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/waiting',
    name: 'Waiting',
    component: () => import(/* webpackChunkName: "about" */ '../views/WaitingView.vue')
  },
  {
    path: '/memory',
    name: 'Memory',
    component: () => import(/* webpackChunkName: "about" */ '../views/MemoryView.vue')
  },
  {
    path: '/vragen',
    name: 'Vragen',
    component: () => import(/* webpackChunkName: "about" */ '../views/VragenView.vue')
  },
  {
    path: '/lijnen/:lijn',
    name: 'Lijnen',
    component: () => import(/* webpackChunkName: "about" */ '../views/LijnenView.vue')
  },
  {
    path: '/dice',
    name: 'Dice',
    component: () => import(/* webpackChunkName: "about" */ '../views/DiceView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
